import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Instagram from "../assets/images/Instagram.svg";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import ProviderLogo1 from "../assets/images/providers/Evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/Ezugi.jpg";
import ProviderLogo3 from "../assets/images/providers/Supernowa.jpg";
import ProviderLogo4 from "../assets/images/providers/XPG.jpg";
import ProviderLogo5 from "../assets/images/providers/Vivogaming.jpg";
import ProviderLogo7 from "../assets/images/providers/OneTouch.jpg";
import ProviderLogo8 from "../assets/images/providers/Pragmaticplay.jpg";
import ProviderLogo9 from "../assets/images/providers/Onlyplay.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";
import Aviator from "../assets/images/games-icon/Aviator.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Sports from "../assets/images/games-icon/headicon/sports.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import HomeIcon from "../assets/images/games-icon/headicon/house.png";

const BeforeLoginFooter = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="inner-content">
        <div className="providers_sec">
          <Row>
            <Col md={6} xs={12}>
              <div className="provider-mob-border">
                <div className="heading mb-4">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>
                      Play Your favorite Casino Games with{" "}
                      <span>Top Providers</span>
                    </p>
                  </div>
                </div>

                <div className="providers_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/xpg" className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </a>
                  <a href="/casino/vivo" className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </a>
                  {/* <a href="/casino/qtech" className="logo_box">
                    <img src={ProviderLogo6} alt="Provider Logo" />
                  </a> */}
                  <a href="/casino/onetouch" className="logo_box">
                    <img src={ProviderLogo7} alt="Provider Logo" />
                  </a>
                  <a href="/casino/pragmatic" className="logo_box">
                    <img src={ProviderLogo8} alt="Provider Logo" />
                  </a>
                  <a href="/casino/onlyplay" className="logo_box">
                    <img src={ProviderLogo9} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="">
                <div className="heading payment-heading mb-4 ">
                  <div className="text">
                    <h5>Payment Gateway</h5>
                    <p>
                      Accept Process &amp; disburse{" "}
                      <span>Digital Payments </span>
                      for your bussiness
                    </p>
                  </div>
                </div>

                <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={PaymentLogo1} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/pragmatic"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={PaymentLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo4} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo5} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo6} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo7} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="container">
          <div className="providers_img">
            <a href="#">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="#">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="#">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="#">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links">
            <ul>
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicy ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegame">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplay">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrule">Games Rules</a>
              </li>
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "#"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us
            </a>
            {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
          </div>

          <div className="d-flex justify-content-center mt-2 d-none">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mb-3">
            <img
              src={Betfair}
              loading="lazy"
              className="mb-3  mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="#">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                className="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {" "}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  {/* <span className="notification-count">2</span> */}
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={Sports} alt="sports" />
                  <span>Sports</span>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={Evolution} alt="evolution" />
                  <span>Evolution</span>
                </a>
              </li>
              <li>
                <a className="active" href="/">
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={Ezugi} alt="ezugi" />
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={Aviator} alt="aviator" />
                  <span>Aviator</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
