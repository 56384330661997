import { Navigate } from "react-router-dom";
import {
  APP_DEFAULT_LAYOUT_SETTING,
  APP_ROUTES_TYPE,
  DEFAULT_APP_ROUTES,
} from "./config/const";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import Footer from "./containers/Footer";
import Header from "./containers/Header";
import HeaderAfterLogin from "./containers/HeaderAfterLogin";

const ProtectedLayout = ({ children, layout, ...props }) => {
  return (
    <>
      {layout?.header === true ? <HeaderAfterLogin {...props} /> : null}
      {children}
      {layout?.footer === true ? <Footer {...props} /> : null}
    </>
  );
};

const PublicLayout = ({ children, layout, ...props }) => {
  return (
    <>
      {layout?.header === true ? <Header {...props} /> : null}
      {children}
      {layout?.footer === true ? <BeforeLoginFooter {...props} /> : null}
    </>
  );
};

const RenderAppLayout = ({
  children,
  isAuth,
  routeType,
  layout = APP_DEFAULT_LAYOUT_SETTING,
  ...props
}) => {
  if (routeType === APP_ROUTES_TYPE.PROTECTED) {
    return <ProtectedLayout children={children} layout={layout} {...props} />;
  } else if (routeType === APP_ROUTES_TYPE.PUBLIC) {
    return <PublicLayout children={children} layout={layout} {...props} />;
  } else {
    if (isAuth) {
      return <ProtectedLayout children={children} layout={layout} {...props} />;
    } else {
      return <PublicLayout children={children} layout={layout} {...props} />;
    }
  }
};

export const RouteGuard = ({
  children,
  isAuth = false,
  routeType = APP_ROUTES_TYPE.PROTECTED,
  redirectPath = "",
  ...props
}) => {
  if (!isAuth && routeType === APP_ROUTES_TYPE.PROTECTED) {
    return <Navigate to={redirectPath || DEFAULT_APP_ROUTES.PUBLIC} replace />;
  }

  if (isAuth && routeType === APP_ROUTES_TYPE.PUBLIC) {
    return (
      <Navigate to={redirectPath || DEFAULT_APP_ROUTES.PROTECTED} replace />
    );
  }

  return (
    <RenderAppLayout
      children={children}
      isAuth={isAuth}
      routeType={routeType}
      {...props}
    />
  );
};
