export const casinoProvidersGamesdata = {
  evolution: {
    Roulette: [
      {
        href: "/casino/ezgevo-american-roulette",
        code: "1000011",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/AmericanRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "american roulette",
        name: "American Roulette",
      },
      {
        href: "/casino/ezgevo-auto-roulette",
        code: "",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/AutoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "auto roulette",
        name: "Auto Roulette",
      },
      {
        href: "/casino/ezgevo-auto-roulette-vip",
        code: "1000126",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/AutoRouletteVIP.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "auto roulette vip",
        name: "Auto Roulette VIP",
      },
      {
        href: "/casino/ezgevo-double-ball-roulette",
        code: "1000084",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/DoubleBallRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "double ball roulette",
        name: "Double Ball Roulette",
      },
      {
        href: "/casino/ezgevo-first-person-american-roulette",
        code: "1000312",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/roulette/FirstPersonAmericanRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person american roulette",
        name: "First Person American Roulette",
      },
      {
        href: "/casino/ezgevo-first-person-lightning-roulette",
        code: "1000120",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/roulette/FirstPersonLightningRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person lightning roulette",
        name: "First Person Lightning Roulette",
      },
      {
        href: "/casino/ezgevo-first-person-roulette",
        code: "1000118",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/FirstPersonRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person roulette",
        name: "First Person Roulette",
      },
      {
        href: "/casino/ezgevo-immersive-roulette",
        code: "1000122",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/ImmersiveRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "immersive roulette",
        name: "Immersive Roulette",
      },
      {
        href: "/casino/ezgevo-instant-roulette",
        code: "1000146",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/InstantRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "instant roulette",
        name: "Instant Roulette",
      },
      {
        href: "/casino/ezgevo-lightning-roulette",
        code: "1000092",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/LightningRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lightning roulette",
        name: "Lightning Roulette",
      },
      {
        href: "/casino/ezgevo-roulette",
        code: "1000094",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/Roulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "roulette",
        name: "Roulette",
      },
      {
        href: "/casino/ezgevo-salon-prive-roulette",
        code: "1000102",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/SalonPrivatRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon privat roulette",
        name: "Salon Privat Roulette",
      },
      {
        href: "/casino/ezgevo-speed-auto-roulette",
        code: "1000103",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/SpeedAutoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed auto roulette",
        name: "Speed Auto Roulette",
      },
      {
        href: "/casino/ezgevo-speed-roulette",
        code: "1000104",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/SpeedRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed roulette",
        name: "Speed Roulette",
      },
      {
        href: "/casino/ezgevo-turkish-lightning-roulette",
        code: "",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/roulette/TurkishLightningRulet.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "turkish lightning rulet",
        name: "Turkish Lightning Rulet",
      },
      {
        href: "/casino/ezgevo-vip-roulette",
        code: "1000124",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/roulette/VIPRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "vip roulette",
        name: "VIP Roulette",
      },
    ],
    Blackjack: [
      {
        href: "/casino/ezgevo-blackjack-a",
        code: "1000030",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack a",
        name: "Blackjack A",
      },
      {
        href: "/casino/ezgevo-blackjack-B",
        code: "1000031",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack b",
        name: "Blackjack B",
      },
      {
        href: "/casino/ezgevo-blackjack-c",
        code: "1000032",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackC.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack c",
        name: "Blackjack C",
      },
      {
        href: "/casino/ezgevo-blackjack-party",
        code: "1000040",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackParty.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack party",
        name: "Blackjack Party",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-a",
        code: "1000041",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver a",
        name: "Blackjack Silver A",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-b",
        code: "1000042",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver b",
        name: "Blackjack Silver B",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-c",
        code: "1000043",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverC.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver c",
        name: "Blackjack Silver C",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-d",
        code: "1000044",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver d",
        name: "Blackjack Silver D",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-e",
        code: "1000045",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverE.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver d",
        name: "Blackjack Silver E",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-f",
        code: "1000046",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverF.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver f",
        name: "Blackjack Silver F",
      },
      {
        href: "/casino/ezgevo-blackjack-silver-g",
        code: "1000047",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackSilverG.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack silver g",
        name: "Blackjack Silver G",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-1",
        code: "1000278",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 1",
        name: "Blackjack Vip 1",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-2",
        code: "1000279",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 2",
        name: "Blackjack Vip 2",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-3",
        code: "1000280",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 3",
        name: "Blackjack Vip 3",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-4",
        code: "1000281",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP4.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 4",
        name: "Blackjack Vip 4",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-5",
        code: "1000282",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 5",
        name: "Blackjack Vip 5",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-6",
        code: "1000283",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP6.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 6",
        name: "Blackjack Vip 6",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-7",
        code: "1000284",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 7",
        name: "Blackjack Vip 7",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-8",
        code: "1000285",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP8.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 8",
        name: "Blackjack Vip 8",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-9",
        code: "1000286",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP9.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 9",
        name: "Blackjack Vip 9",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-10",
        code: "1000287",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP10.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 10",
        name: "Blackjack Vip 10",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-11",
        code: "1000288",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP11.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 11",
        name: "Blackjack Vip 11",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-12",
        code: "1000289",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP12.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 12",
        name: "Blackjack Vip 12",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-13",
        code: "1000290",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP13.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 13",
        name: "Blackjack Vip 13",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-4",
        code: "1000291",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP14.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 14",
        name: "Blackjack Vip 14",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-15",
        code: "1000292",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP15.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 15",
        name: "Blackjack Vip 15",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-16",
        code: "1000293",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIP16.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip 16",
        name: "Blackjack Vip 16",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-a",
        code: "1000048",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip a",
        name: "Blackjack Vip A",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-alpha",
        code: "1000162",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPAlpha.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip alpha",
        name: "Blackjack Vip Alpha",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-b",
        code: "1000049",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip b",
        name: "Blackjack Vip B",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-beta",
        code: "1000163",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPBeta.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip beta",
        name: "Blackjack Vip Beta",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-c",
        code: "1000050",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPC.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip c",
        name: "Blackjack Vip C",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-d",
        code: "1000051",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip d",
        name: "Blackjack Vip D",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-e",
        code: "1000052",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPE.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip e",
        name: "Blackjack Vip E",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-g",
        code: "1000053",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPG.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip g",
        name: "Blackjack Vip G",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-gamma",
        code: "1000164",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPGamma.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip gamma",
        name: "Blackjack Vip Gamma",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-h",
        code: "1000054",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPH.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip h",
        name: "Blackjack Vip H",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-i",
        code: "1000055",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPI.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip i",
        name: "Blackjack Vip I",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-l",
        code: "1000056",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPL.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip l",
        name: "Blackjack Vip L",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-m",
        code: "1000057",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPM.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip m",
        name: "Blackjack Vip M",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-o",
        code: "1000058",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPO.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip o",
        name: "Blackjack Vip O",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-p",
        code: "1000059",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPP.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip p",
        name: "Blackjack Vip P",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-q",
        code: "1000155",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPQ.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip q",
        name: "Blackjack Vip Q",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-r",
        code: "1000060",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPR.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip r",
        name: "Blackjack Vip R",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-s",
        code: "1000061",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPS.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip s",
        name: "Blackjack Vip S",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-t",
        code: "1000062",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPT.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip t",
        name: "Blackjack Vip T",
      },
      {
        href: "/casino/ezgevo-blackjack-vip-x",
        code: "1000035",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/BlackjackVIPX.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack vip x",
        name: "Blackjack Vip X",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-1",
        code: "1000226",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 1",
        name: "Classic Speed Blackjack 1",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-2",
        code: "1000227",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 2",
        name: "Classic Speed Blackjack 2",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-3",
        code: "1000228",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 3",
        name: "Classic Speed Blackjack 3",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-4",
        code: "1000229",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack4.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 4",
        name: "Classic Speed Blackjack 4",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-5",
        code: "1000230",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 5",
        name: "Classic Speed Blackjack 5",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-6",
        code: "1000231",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack6.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 6",
        name: "Classic Speed Blackjack 6",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-6",
        code: "1000231",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack6.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 6",
        name: "Classic Speed Blackjack 6",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-8",
        code: "1000233",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack8.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 8",
        name: "Classic Speed Blackjack 8",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-10",
        code: "1000235",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack10.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 10",
        name: "Classic Speed Blackjack 10",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-11",
        code: "1000236",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack11.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 11",
        name: "Classic Speed Blackjack 11",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-12",
        code: "1000237",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack12.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 12",
        name: "Classic Speed Blackjack 12",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-13",
        code: "1000238",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack13.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 13",
        name: "Classic Speed Blackjack 13",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-14",
        code: "1000239",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack14.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 14",
        name: "Classic Speed Blackjack 14",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-15",
        code: "1000240",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack15.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 15",
        name: "Classic Speed Blackjack 15",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-16",
        code: "1000241",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack16.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 16",
        name: "Classic Speed Blackjack 16",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-17",
        code: "1000242",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack17.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 17",
        name: "Classic Speed Blackjack 17",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-18",
        code: "1000243",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack18.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 18",
        name: "Classic Speed Blackjack 18",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-19",
        code: "1000244",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack19.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 19",
        name: "Classic Speed Blackjack 19",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-20",
        code: "1000297",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack20.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 20",
        name: "Classic Speed Blackjack 20",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-21",
        code: "1000298",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack21.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 21",
        name: "Classic Speed Blackjack 21",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-22",
        code: "1000299",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack22.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 22",
        name: "Classic Speed Blackjack 22",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-23",
        code: "1000300",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack23.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 23",
        name: "Classic Speed Blackjack 23",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-24",
        code: "1000301",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack24.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 24",
        name: "Classic Speed Blackjack 24",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-25",
        code: "1000302",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack25.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 25",
        name: "Classic Speed Blackjack 25",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-26",
        code: "1000303",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack26.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 26",
        name: "Classic Speed Blackjack 26",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-27",
        code: "1000304",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack27.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 27",
        name: "Classic Speed Blackjack 27",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-28",
        code: "1000305",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack28.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 28",
        name: "Classic Speed Blackjack 28",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-29",
        code: "1000306",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack29.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 29",
        name: "Classic Speed Blackjack 29",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-30",
        code: "1000307",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack30.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 30",
        name: "Classic Speed Blackjack 30",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-31",
        code: "1000308",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack31.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 31",
        name: "Classic Speed Blackjack 31",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-32",
        code: "1000309",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack32.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 32",
        name: "Classic Speed Blackjack 32",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-33",
        code: "1000310",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack33.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 33",
        name: "Classic Speed Blackjack 33",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-34",
        code: "1000311",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack34.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 34",
        name: "Classic Speed Blackjack 34",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-38",
        code: "1000319",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack38.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 38",
        name: "Classic Speed Blackjack 38",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-39",
        code: "1000320",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack39.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 39",
        name: "Classic Speed Blackjack 39",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-40",
        code: "1000321",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack40.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 40",
        name: "Classic Speed Blackjack 40",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-41",
        code: "1000322",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack41.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 41",
        name: "Classic Speed Blackjack 41",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-42",
        code: "1000323",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack42.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 42",
        name: "Classic Speed Blackjack 42",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-43",
        code: "1000324",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack43.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 43",
        name: "Classic Speed Blackjack 43",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-44",
        code: "1000325",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack44.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 44",
        name: "Classic Speed Blackjack 44",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-45",
        code: "1000326",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack45.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 45",
        name: "Classic Speed Blackjack 45",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-46",
        code: "1000327",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack46.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 46",
        name: "Classic Speed Blackjack 46",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-47",
        code: "1000328",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack47.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 47",
        name: "Classic Speed Blackjack 47",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-48",
        code: "1000329",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack48.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 48",
        name: "Classic Speed Blackjack 48",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-49",
        code: "1000330",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack49.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 49",
        name: "Classic Speed Blackjack 49",
      },
      {
        href: "/casino/ezgevo-classic-speed-blackjack-50",
        code: "1000331",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/ClassicSpeedBlackjack50.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic speed blackjack 50",
        name: "Classic Speed Blackjack 50",
      },
      {
        href: "/casino/ezgevo-first-person-blackjack",
        code: "1000119",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/FirstPersonBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person blackjack",
        name: "First Person Blackjack",
      },
      {
        href: "/casino/ezgevo-first-person-lightning-blackjack",
        code: "1000359",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/FirstPersonLightningBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person lightning blackjack",
        name: "First Person Lightning Blackjack",
      },
      {
        href: "/casino/ezgevo-free-bet-blackjack",
        code: "1000066",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/FreeBetBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "free bet blackjack",
        name: "Free Bet Blackjack",
      },
      {
        href: "/casino/ezgevo-infinite-blackjack",
        code: "1000067",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/InfiniteBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "infinite blackjack",
        name: "Infinite Blackjack",
      },
      {
        href: "/casino/ezgevo-lightning-blackjack",
        code: "1000358",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/LightningBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lightning blackjack",
        name: "Lightning Blackjack",
      },
      {
        href: "/casino/ezgevo-power-blackjack",
        code: "1000147",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/PowerBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "power blackjack",
        name: "Power Blackjack",
      },
      {
        href: "/casino/ezgevo-salon-priv-blackjack-a",
        code: "1000068",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/SalonPrivatBlackjackA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv blackjack a",
        name: "Salon Priv Blackjack A",
      },
      {
        href: "/casino/ezgevo-salon-priv-blackjack-b",
        code: "1000069",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/SalonPrivatBlackjackB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv blackjack b",
        name: "Salon Priv Blackjack B",
      },
      {
        href: "/casino/ezgevo-salon-priv-blackjack-c",
        code: "1000070",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/SalonPrivatBlackjackC.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv blackjack c",
        name: "Salon Priv Blackjack C",
      },
      {
        href: "/casino/ezgevo-salon-priv-blackjack-d",
        code: "1000158",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/SalonPrivatBlackjackD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv blackjack d",
        name: "Salon Priv Blackjack D",
      },
      {
        href: "/casino/ezgevo-salon-priv-blackjack-e",
        code: "1000159",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/SalonPrivatBlackjackE.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv blackjack e",
        name: "Salon Priv Blackjack E",
      },
      {
        href: "/casino/ezgevo-salon-priv-blackjack-f",
        code: "1000160",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/blackjack/SalonPrivatBlackjackF.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv blackjack f",
        name: "Salon Priv Blackjack F",
      },
      // {
      //   href: "/casino/ezgevo-speed-blackjack-a",
      //   code: "1000132",
      //   casino: "evolution",
      //   provider: "Evolution",
      //   homeUrl: "/evolution",
      //   imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackA.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "speed blackjack a",
      //   name: "Speed Blackjack A",
      // },
      // {
      //   href: "/casino/ezgevo-speed-blackjack-b",
      //   code: "1000133",
      //   casino: "evolution",
      //   provider: "Evolution",
      //   homeUrl: "/evolution",
      //   imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackB.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "speed blackjack b",
      //   name: "Speed Blackjack B",
      // },
      // {
      //   href: "/casino/ezgevo-speed-blackjack-c",
      //   code: "1000134",
      //   casino: "evolution",
      //   provider: "Evolution",
      //   homeUrl: "/evolution",
      //   imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackC.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "speed blackjack c",
      //   name: "Speed Blackjack C",
      // },
      {
        href: "/casino/ezgevo-speed-blackjack-d",
        code: "1000033",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack d",
        name: "Speed Blackjack D",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-e",
        code: "1000252",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackE.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack e",
        name: "Speed Blackjack E",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-g",
        code: "1000253",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackG.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack g",
        name: "Speed Blackjack G",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-h",
        code: "1000254",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackH.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack h",
        name: "Speed Blackjack H",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-i",
        code: "1000255",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackI.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack i",
        name: "Speed Blackjack I",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-j",
        code: "1000256",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackJ.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack j",
        name: "Speed Blackjack J",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-k",
        code: "1000063",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackK.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack k",
        name: "Speed Blackjack K",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-l",
        code: "1000064",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackL.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack l",
        name: "Speed Blackjack L",
      },
      {
        href: "/casino/ezgevo-speed-blackjack-m",
        code: "1000065",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedBlackjackM.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed blackjack m",
        name: "Speed Blackjack M",
      },
      {
        href: "/casino/ezgevo-speed-vip-blackjack-d",
        code: "1000135",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/blackjack/SpeedVIPBlackjackD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed vip blackjack d",
        name: "Speed VIP Blackjack D",
      },
    ],
    Baccarat: [
      {
        href: "/casino/ezgevo-baccarat-a",
        code: "1000012",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/BaccaratA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat a",
        name: "Baccarat A",
      },
      {
        href: "/casino/ezgevo-baccarat-b",
        code: "1000013",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/BaccaratB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat b",
        name: "Baccarat B",
      },
      {
        href: "/casino/ezgevo-baccarat-a",
        code: "1000014",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/BaccaratA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat a",
        name: "Baccarat A",
      },
      {
        href: "/casino/ezgevo-baccarat-control-squeeze",
        code: "1000015",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/BaccaratControlSqueeze.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat control squeeze",
        name: "Baccarat Control Squeeze",
      },
      {
        href: "/casino/ezgevo-baccarat-squeeze",
        code: "1000016",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/BaccaratSqueeze.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat squeeze",
        name: "Baccarat Squeeze",
      },
      {
        href: "/casino/ezgevo-first-person-baccarat",
        code: "1000145",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/FirstPersonBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person baccarat",
        name: "First Person Baccarat",
      },
      {
        href: "/casino/ezgevo-first-person-golden-wealth-baccarat",
        code: "1000356",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/FirstPersonGoldenWealthBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person golden wealth baccarat",
        name: "First Person Golden Wealth Baccarat",
      },
      {
        href: "/casino/ezgevo-first-person-lightning-baccarat",
        code: "1000357",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/FirstPersonLightningBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person lightning baccarat",
        name: "First Person lightning Baccarat",
      },
      {
        href: "/casino/ezgevo-golden-wealth-baccarat",
        code: "1000355",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/GoldenWealthBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "golden wealth baccarat",
        name: "Golden Wealth Baccarat",
      },
      {
        href: "/casino/ezgevo-lightning-baccarat",
        code: "1000149",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/LightningBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lightning baccarat",
        name: "Lightning Baccarat",
      },
      {
        href: "/casino/ezgevo-no-commission-baccarat-evo",
        code: "1000018",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/NoCommissionBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "no commission baccarat",
        name: "No Commission Baccarat-EVO",
      },
      {
        href: "/casino/ezgevo-no-commission-speed-baccarat-a",
        code: "1000017",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/NoCommSpeedBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "no commission speed baccarat a",
        name: "No Commission Speed Baccarat A",
      },
      {
        href: "/casino/ezgevo-salon-priv-baccarat-a",
        code: "1000020",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/SalonPrivatBaccaratA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv baccarat a",
        name: "Salon Priv Baccarat A",
      },
      {
        href: "/casino/ezgevo-salon-prive-baccarat-b",
        href: "/casino/ezgevo-salon-priv-baccarat-b",
        code: "1000127",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/SalonPrivatBaccaratB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv baccarat b",
        name: "Salon Priv Baccarat B",
      },
      {
        href: "/casino/ezgevo-salon-priv-baccarat-c",
        code: "1000156",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/SalonPrivatBaccaratC.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv baccarat c",
        name: "Salon Priv Baccarat C",
      },
      {
        href: "/casino/ezgevo-salon-priv-baccarat-d",
        code: "1000157",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/SalonPrivatBaccaratD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv baccarat d",
        name: "Salon Priv Baccarat D",
      },
      {
        href: "/casino/ezgevo-salon-priv-baccarat-e",
        code: "1000351",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/baccarat/SalonPrivatBaccaratE.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "salon priv baccarat e",
        name: "Salon Priv Baccarat E",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-a",
        code: "1000021",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratA.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat a",
        name: "Speed Baccarat A",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-b",
        code: "1000022",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratB.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat b",
        name: "Speed Baccarat B",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-c",
        code: "1000023",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratC.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat c",
        name: "Speed Baccarat C",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-d",
        code: "1000024",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat d",
        name: "Speed Baccarat D",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-e",
        code: "1000025",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratE.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat e",
        name: "Speed Baccarat E",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-f",
        code: "1000026",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratF.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat f",
        name: "Speed Baccarat F",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-g",
        code: "1000027",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratG.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat g",
        name: "Speed Baccarat G",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-h",
        code: "1000028",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratH.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat h",
        name: "Speed Baccarat H",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-i",
        code: "1000029",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratI.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat i",
        name: "Speed Baccarat I",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-j",
        code: "1000129",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratJ.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat j",
        name: "Speed Baccarat J",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-k",
        code: "1000277",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratK.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat k",
        name: "Speed Baccarat K",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-l",
        code: "1000276",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratL.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat l",
        name: "Speed Baccarat L",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-m",
        code: "1000275",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratM.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat m",
        name: "Speed Baccarat M",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-n",
        code: "1000274",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratN.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat n",
        name: "Speed Baccarat N",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-o",
        code: "1000273",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratO.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat o",
        name: "Speed Baccarat O",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-p",
        code: "1000272",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratP.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat p",
        name: "Speed Baccarat P",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-q",
        code: "1000269",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratQ.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat q",
        name: "Speed Baccarat Q",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-r",
        code: "1000270",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratR.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat r",
        name: "Speed Baccarat R",
      },
      {
        href: "/casino/ezgevo-speed-baccarat-s",
        code: "1000271",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/baccarat/SpeedBaccaratS.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed baccarat s",
        name: "Speed Baccarat S",
      },
    ],
    Poker: [
      {
        href: "/casino/ezgevo-casino-holdem",
        href: "/casino/ezgevo-casino-holdem-evo",
        code: "507000",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/poker/CasinoHoldem.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino hold'em",
        name: "Casino Hold'em-EVO",
      },
      {
        href: "/casino/ezgevo-2-hand-casino-holdem",
        code: "1000073",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/poker/HandCasinoHoldem2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "2 hand casino holdem",
        name: "2 Hand Casino Holdem",
      },
      {
        href: "/casino/ezgevo-side-bet-city",
        code: "1000115",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/poker/SideBetCity.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "side bet city",
        name: "Side Bet City",
      },
      {
        href: "/casino/ezgevo-texas-holdem-bonus-poker",
        code: "1000111",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/poker/TexasHoldemBonusPoker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "texas holdem bonus poker",
        name: "Texas Holdem Bonus Poker",
      },
      {
        href: "/casino/ezgevo-three-card-poker",
        code: "1000152",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/poker/ThreeCardPoker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "three card poker",
        name: "Three Card Poker",
      },
      {
        href: "/casino/ezgevo-ultimate-texas-holdem",
        code: "1000151",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/poker/UltimateTexasHoldem.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "ultimate texas holdem",
        name: "Ultimate Texas Holdem",
      },
    ],
    Sicbo: [
      {
        href: "/casino/ezgevo-dragon-tiger-evo",
        code: "1000074",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/sicbo/DragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon tiger",
        name: "Dragon Tiger-EVO",
      },
      {
        href: "/casino/ezgevo-first-person-dragon-tiger",
        code: "1000143",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/sicbo/FirstPersonDragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person dragon tiger",
        name: "First Person Dragon Tiger",
      },
      {
        href: "/casino/ezgevo-super-sic-bo",
        code: "1000110",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/sicbo/SuperSicBo.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "super sic bo",
        name: "Super Sic Bo",
      },
    ],
    GameShows: [
      {
        href: "/casino/ezgevo-cash-or-crash",
        code: "1000352",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/game-shows/CashorCrash.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cash or crash",
        name: "Cash or Crash",
      },
      {
        href: "/casino/ezgevo-crazy-time",
        code: "1000148",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/game-shows/CrazyTime.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "crazy time",
        name: "Crazy Time",
      },
      {
        href: "/casino/ezgevo-dream-catcher",
        code: "1000077",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/game-shows/DreamCatcher.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dream catcher",
        name: "Dream Catcher",
      },
      {
        href: "/casino/ezgevo-first-person-dream-catcher",
        code: "1000121",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/game-shows/FirstPersonDreamCatcher.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person dream catcher",
        name: "First Person Dream Catcher",
      },
      {
        href: "/casino/ezgevo-first-person-mega-ball",
        code: "1000142",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/game-shows/FirstPersonMegaball.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person mega ball",
        name: "First Person Mega Ball",
      },
      {
        href: "/casino/ezgevo-first-person-top-card",
        code: "1000144",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/game-shows/FirstPersonTopCard.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "first person top card",
        name: "First Person Top Card",
      },
      {
        href: "/casino/ezgevo-football-studio",
        code: "1000112",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/game-shows/Footballstudio.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "football studio",
        name: "Football Studio",
      },
      {
        href: "/casino/ezgevo-gonzos-treasure-hunt",
        code: "1100022",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl:
          "./images/providers/evolution/game-shows/GonzosTreasureHunt.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gonzos treasure hunt",
        name: "Gonzos Treasure Hunt",
      },
      {
        href: "/casino/ezgevo-lightning-dice",
        code: "1000076",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/game-shows/LightningDice.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lightning dice",
        name: "Lightning Dice",
      },
      {
        href: "/casino/ezgevo-mega-ball",
        code: "1000141",
        casino: "evolution",
        provider: "Evolution",
        homeUrl: "/evolution",
        imgUrl: "./images/providers/evolution/game-shows/Megaball.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "mega ball",
        name: "Mega Ball",
      },
    ],
  },

  ezugi: {
    Roulette: [
      {
        href: "/casino/ezg-auto-roulette",
        code: "5001",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/AutoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "auto roulette",
        name: "Auto Roulette",
      },
      {
        href: "/casino/ezg-casino-marina-roulette-1",
        code: "321000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/CasinoMarinaRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina roulette 1",
        name: "Casino Marina Roulette 1",
      },
      {
        href: "/casino/ezg-casino-marina-roulette-2",
        code: "321001",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/CasinoMarinaRoulette2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina roulette 2",
        name: "Casino Marina Roulette 2",
      },
      {
        href: "/casino/ez-cumbia-ruleta-1",
        code: "411000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/CumbiaRuleta1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cumbia ruleta 1",
        name: "Cumbia Ruleta 1",
      },
      {
        href: "/casino/ezg-diamond-roulette",
        code: "221003",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/DiamondRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "diamond roulette",
        name: "Diamond Roulette",
      },
      {
        href: "/casino/ezg-fiesta-roulette",
        code: "431001",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/FiestaRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fiesta roulette",
        name: "Fiesta Roulette",
      },
      {
        href: "/casino/ezg-italian-roulette",
        code: "1000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/ItalianRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "italian roulette",
        name: "Italian Roulette",
      },
      // {
      //   href: "/casino/ezg-lumina-auto-roulette",
      //   code: "611005",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/roulette/LuminaAutoRoulette.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "lumina auto roulette",
      //   name: "Lumina Auto Roulette",
      // },
      {
        href: "/casino/ezg-namaste-roulette",
        code: "221005",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/NamasteRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "namaste roulette",
        name: "Namaste Roulette",
      },
      {
        href: "/casino/ezg-oracle-blaze-roulette",
        code: "611006",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/OracleBlaze.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "oracle blaze roulette",
        name: "Oracle Blaze Roulette",
      },
      {
        href: "/casino/ezg-oracle-roulette-2",
        code: "611001",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/OracleCasinoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "oracle roulette 2",
        name: "Oracle Roulette 2",
      },
      {
        href: "/casino/ez-oracle-roulette-360",
        code: "611003",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/OracleRoulette360.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "oracle roulette 360",
        name: "Oracle Roulette 360",
      },
      {
        href: "/casino/ez-portomaso-real-casino-roulette",
        code: "611004",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl:
          "./images/providers/ezugi/roulette/PortomasoRealCasinoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "portomaso real casino roulette",
        name: "Portomaso Real Casino Roulette",
      },
      {
        href: "/casino/ez-portomaso-real-casino-roulette-2",
        code: "611000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl:
          "./images/providers/ezugi/roulette/PortomasoRealCasinoRoulette2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "portomaso real casino roulette 2",
        name: "Portomaso Real Casino Roulette 2",
      },
      {
        href: "/casino/ezg-prestige-auto-roulette",
        code: "221004",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/PrestigeAutoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "prestige auto roulette",
        name: "Prestige Auto Roulette",
      },
      {
        href: "/casino/ezg-ruleta-del-sol",
        code: "431000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/RuletadelSol.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "ruleta del sol",
        name: "Ruleta Del Sol",
      },
      {
        href: "/casino/ez-ruletka-russia",
        code: "601000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/RuletkaRussia.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "ruletka russia",
        name: "Ruletka Russia",
      },
      // {
      //   href: "/casino/ez-slavyanka-roulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/roulette/SlavyankaRoulette.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "slavyanka roulette",
      //   name: "Slavyanka Roulette",
      // },
      {
        href: "/casino/ezg-speed-auto-roulette",
        code: "221002",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/SpeedAutoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed auto roulette",
        name: "Speed Auto Roulette",
      },
      {
        href: "/casino/ezg-speed-roulette",
        code: "221000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/SpeedRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed roulette",
        name: "Speed Roulette",
      },
      {
        href: "/casino/ez-turkce-rulet",
        code: "221002",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/roulette/TürkçeRulet.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "türkçe tulet",
        name: "Türkçe Rulet",
      },
    ],
    Blackjack: [
      // {
      //   href: "/casino/ezg-all-stars-blackjack",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/blackjack/AllStarsBlackjack.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "all stars blackjack",
      //   name: "All Stars Blackjack",
      // },
      {
        href: "/casino/ezg-blackjack-1",
        code: "201",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/Blackjack1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack 1",
        name: "Blackjack 1",
      },
      {
        href: "/casino/ezg-russian-blackjack-2",
        code: "202",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/Blackjack2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "russian blackjack 2",
        name: "Russian Blackjack 2",
      },
      {
        href: "/casino/ezg-blackjack-7",
        code: "221",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/Blackjack7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack 7",
        name: "Blackjack 7",
      },
      // {
      //   href: "/casino/ez-blackjack-bucuresti",
      //   code: "205",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/blackjack/BlackjackBucuresti.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "blackjack bucuresti",
      //   name: "Blackjack Bucuresti",
      // },
      {
        href: "/casino/ez-blackjack-gold-1",
        code: "204",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackGold1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack gold 1",
        name: "Blackjack Gold 1",
      },
      {
        href: "/casino/ez-blackjack-gold-3",
        code: "203",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackGold3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack gold 3",
        name: "Blackjack Gold 3",
      },
      {
        href: "/casino/ez-blackjack-gold-4",
        code: "4",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackGold4.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack gold 4",
        name: "Blackjack Gold 4",
      },
      {
        href: "/casino/ez-blackjack-gold-5",
        code: "1",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackGold5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack gold 5",
        name: "Blackjack Gold 5",
      },
      {
        href: "/casino/ez-blackjack-gold-6",
        code: "223",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackGold6.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack gold 6",
        name: "Blackjack Gold 6",
      },
      {
        href: "/casino/ez-blackjack-platinum-1",
        code: "5",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackPlatinum1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack platinum 1",
        name: "Blackjack Platinum 1",
      },
      {
        href: "/casino/ez-blackjack-salon-prive",
        code: "227",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackjackSalonPrivé.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack salon privé",
        name: "Blackjack Salon Privé",
      },
      {
        href: "/casino/ez-black-russian",
        code: "426",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/BlackRussianBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "black russian",
        name: "Black Russian",
      },
      // {
      //   href: "/casino/ezg-club-blackjack",
      //   code: "502",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/blackjack/ClubBlackjack.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "club blackjack",
      //   name: "Club Blackjack",
      // },
      // {
      //   href: "/casino/ezg-club-blackjack-1",
      //   code: "503",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/blackjack/ClubBlackjack1.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "club blackjack 1",
      //   name: "Club Blackjack 1",
      // },
      {
        href: "/casino/ez-diamond-vip-blackjack",
        code: "225",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/DiamondVIPBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "diamond vip blackjack",
        name: "Diamond VIP Blackjack",
      },
      // {
      //   href: "/casino/ez-fiesta-blackjack-unlimited",
      //   code: "4351",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl:
      //     "./images/providers/ezugi/blackjack/FiestaBlackjackUnlimited.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "fiesta blackjack unlimited",
      //   name: "Fiesta Blackjack Unlimited",
      // },
      {
        href: "/casino/ezg-italian-blackjack",
        code: "226",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/ItalianBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "italian blackjack",
        name: "Italian Blackjack",
      },
      {
        href: "/casino/ez-rumba-blackjack-1",
        code: "411",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/RumbaBlackjack1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rumba blackjack 1",
        name: "Rumba Blackjack 1",
      },
      {
        href: "/casino/ez-rumba-blackjack-2",
        code: "412",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/RumbaBlackjack2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rumba blackjack 2",
        name: "Rumba Blackjack 2",
      },
      {
        href: "/casino/ez-rumba-blackjack-3",
        code: "413",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/RumbaBlackjack3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rumba blackjack 3",
        name: "Rumba Blackjack 3",
      },
      {
        href: "/casino/ez-rumba-blackjack-4",
        code: "414",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/RumbaBlackjack4.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rumba blackjack 4",
        name: "Rumba Blackjack 4",
      },
      {
        href: "/casino/ezg-turkish-blackjack",
        code: "501",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/TurkishBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "turkish blackjack",
        name: "Turkish Blackjack",
      },
      {
        href: "/casino/ezg-turkish-blackjack-1",
        code: "421",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/TurkishBlackjack1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "turkish blackjack 1",
        name: "Turkish Blackjack 1",
      },
      {
        href: "/casino/ezg-turkish-blackjack-2",
        code: "422",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/TurkishBlackjack2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "turkish blackjack 2",
        name: "Turkish Blackjack 2",
      },
      {
        href: "/casino/ezg-unlimited-blackjack",
        code: "51",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/UnlimitedBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "unlimited blackjack",
        name: "Unlimited Blackjack",
      },
      {
        href: "/casino/ez-unlimited-turkish-blackjack",
        code: "5051",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl:
          "./images/providers/ezugi/blackjack/UnlimitedTurkishBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "unlimited turkish blackjack",
        name: "Unlimited Turkish Blackjack",
      },
      // {
      //   href: "/casino/ez-vip-blackjack-2",
      //   code: "222",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/blackjack/VIPBlackjack2.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "vip blackjack 2",
      //   name: "VIP Blackjack 2",
      // },
      {
        href: "/casino/ez-vip-blackjack-with-surrender",
        code: "224",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl:
          "./images/providers/ezugi/blackjack/VIPBlackjackwithSurrender.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "vip blackjack with surrender",
        name: "VIP Blackjack with Surrender",
      },
      {
        href: "/casino/ez-white-russian",
        code: "425",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/blackjack/WhiteRussianBlackjack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "white russian",
        name: "White Russian",
      },
    ],
    Baccarat: [
      {
        href: "/casino/ezg-baccarat",
        code: "100",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/Baccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat",
        name: "Baccarat",
      },
      {
        href: "/casino/ez-baccarat-knock-out",
        code: "120",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/BaccaratKnockOut.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat knock out",
        name: "Baccarat Knock Out",
      },
      {
        href: "/casino/ez-baccarat-no-commission",
        code: "170",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/BaccaratNoCommission.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat no commission",
        name: "Baccarat No Commission",
      },
      {
        href: "/casino/ez-baccarat-super-6",
        code: "130",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/BaccaratSuper6.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat super 6",
        name: "Baccarat Super 6",
      },
      {
        href: "/casino/ez-casino-marina-baccarat-1",
        code: "32100",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/CasinoMarinaBaccarat1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina baccarat 1",
        name: "Casino Marina Baccarat 1",
      },
      {
        href: "/casino/ez-casino-marina-baccarat-2",
        code: "32101",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/CasinoMarinaBaccarat2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina baccarat 2",
        name: "Casino Marina Baccarat 2",
      },
      {
        href: "/casino/ez-casino-marina-baccarat-3",
        code: "32102",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/CasinoMarinaBaccarat3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina baccarat 3",
        name: "Casino Marina Baccarat 3",
      },
      {
        href: "/casino/ez-casino-marina-baccarat-4",
        code: "32103",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/CasinoMarinaBaccarat4.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina baccarat 4",
        name: "Casino Marina Baccarat 4",
      },
      {
        href: "/casino/ez-cricket-war",
        code: "45100",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/CricketWar.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cricket war",
        name: "Cricket War",
      },
      {
        href: "/casino/ezg-dragon-tiger",
        code: "150",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/DragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon tiger",
        name: "Dragon Tiger",
      },
      {
        href: "/casino/ezg-fiesta-baccarat",
        code: "43100",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/FiestaBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fiesta baccarat",
        name: "Fiesta Baccarat",
      },
      {
        href: "/casino/ezg-fortune-baccarat",
        code: "102",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/FortuneBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fortune baccarat",
        name: "Fortune Baccarat",
      },
      // {
      //   href: "/casino/ez-salsa-baccarat-1",
      //   code: "41100",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/baccarat/SalsaBaccarat1.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "salsa baccarat 1",
      //   name: "Salsa Baccarat 1",
      // },
      // {
      //   href: "/casino/ez-salsa-baccarat-2",
      //   code: "41101",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/baccarat/SalsaBaccarat2.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "salsa baccarat 2",
      //   name: "Salsa Baccarat 2",
      // },
      // {
      //   href: "/casino/ez-salsa-baccarat-3",
      //   code: "41102",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/baccarat/SalsaBaccarat3.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "salsa baccarat 3",
      //   name: "Salsa Baccarat 3",
      // },
      // {
      //   href: "/casino/ez-salsa-baccarat-4",
      //   code: "41103",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/baccarat/SalsaBaccarat4.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "salsa baccarat 4",
      //   name: "Salsa Baccarat 4",
      // },
      {
        href: "/casino/ez-speed-cricket-baccarat",
        code: "101",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/baccarat/SpeedCricketBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "speed cricket baccarat",
        name: "Speed Cricket Baccarat",
      },
    ],
    Andarbahar: [
      {
        href: "/casino/ez-andar-bahar",
        code: "228000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/andarbahar/AndarBahar.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "andar bahar",
        name: "Andar Bahar",
      },
      {
        href: "/casino/ezg-casino-marina-andar-bahar",
        code: "328000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/andarbahar/OTTAndarBahar.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino marina andar bahar",
        name: "Casino Marina Andar Bahar",
      },
    ],
    Poker: [
      {
        href: "/casino/ezg-bet-on-teen-patti",
        code: "227101",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/poker/BetonTeenPatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bet on teen patti",
        name: "Bet On Teen Patti",
      },
      {
        href: "/casino/ezg-casino-holdem",
        code: "507000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/poker/CasinoHoldem.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino holdem",
        name: "Casino Holdem",
      },
      {
        href: "/casino/ezg-one-day-teen-patti",
        code: "227102",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/poker/OneDayTeenPatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "one day teenPatti",
        name: "One Day TeenPatti",
      },
      // {
      //   href: "/casino/ezg-one-day-teen-patti-classic",
      //   code: "227103",
      //   casino: "ezugi",
      //   provider: "Ezugi",
      //   homeUrl: "/ezugi",
      //   imgUrl: "./images/providers/ezugi/poker/OneDayTeenPattiClassic.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "one day teen patti classic",
      //   name: "One Day Teen Patti Classic",
      // },
      {
        href: "/casino/ezg-teen-patti-3-card",
        code: "227100",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/poker/TeenPatti3Card.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "teen patti 3 card",
        name: "Teen Patti 3 Card",
      },
    ],
    Sicbo: [
      {
        href: "/casino/ezg-sic-bo",
        code: "224000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/sicbo/SicBo.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "sic bo",
        name: "Sic Bo",
      },
      {
        href: "/casino/ezg-ultimate-sic-bo",
        code: "224100",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/sicbo/UltimateSicBo.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "ultimate sic bo",
        name: "Ultimate Sic Bo",
      },
    ],
    Card32: [
      {
        href: "/casino/ezg-32-cards",
        code: "228002",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/card32/Cards32.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "32 cards",
        name: "32 Cards",
      },
    ],
    Lucky7: [
      {
        href: "/casino/ezg-lucky-7",
        code: "228001",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/lucky7/lucky7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky 7",
        name: "Lucky 7",
      },
    ],
    Lottery: [
      {
        href: "/casino/ezg-bet-on-numbers-hd",
        code: "602000",
        casino: "ezugi",
        provider: "Ezugi",
        homeUrl: "/ezugi",
        imgUrl: "./images/providers/ezugi/lottery/BetonNumbers.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bet on numbers hd",
        name: "Bet On Numbers HD",
      },
    ],
  },

  supernowa: {
    Supernowa: [
      {
        href: "/casino/sn-akbar-romeo-walter",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/AkbarRomeoWalter.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "akbar romeo walter",
        name: "Akbar Romeo Walter",
      },
      {
        href: "/casino/sn-andar-bahar",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/AndarBahar.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "andar bahar",
        name: "Andar Bahar",
      },
      // {
      //   href: "/casino/sn-baccarat",
      //   code: "",
      //   casino: "supernowa",
      //   provider: "WorldCasino",
      //   homeUrl: "/supernowa",
      //   imgUrl: "./images/providers/supernowa/Baccarat.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "baccarat",
      //   name: "Baccarat",
      // },
      {
        href: "/casino/sn-32-cards",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/Cards32.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "32 cards",
        name: "32 Cards",
      },
      {
        href: "/casino/sn-casino-queen",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/CasinoQueen.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "casino queen",
        name: "Casino Queen",
      },
      {
        href: "/casino/sn-classic-andar-bahar",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/ClassicAndarBahar.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "classic andar bahar",
        name: "Classic Andar Bahar",
      },
      {
        href: "/casino/sn-dragon-tiger",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/DragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon tiger",
        name: "Dragon Tiger",
      },
      {
        href: "/casino/sn-goas-andar-bahar",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/GoasAndarBahar.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "goas andar bahar",
        name: "Goas Andar Bahar",
      },
      {
        href: "/casino/sn-king-race",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/KingRace.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "king race",
        name: "King Race",
      },
      {
        href: "/casino/sn-lucky-7",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/Lucky7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky 7",
        name: "Lucky 7",
      },
      {
        href: "/casino/sn-3-player-teenpatti",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/PlayerTeenPatti3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "3 player teenpatti",
        name: "3 Player TeenPatti",
      },
      {
        href: "/casino/sn-poker",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/Poker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "poker",
        name: "Poker",
      },
      {
        href: "/casino/sn-rng-3-cards-judgement",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNG3CardsJudgement.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng 3 cards judgement",
        name: "RNG 3 Cards Judgement",
      },
      {
        href: "/casino/sn-rng-3-player-teen-patti",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNG3PlayerTeenPatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng 3 player teen patti",
        name: "RNG 3 Player Teen Patti",
      },
      {
        href: "/casino/sn-rng-32-Cards",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNG32Cards.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng 32 cards",
        name: "RNG 32 Cards",
      },
      {
        href: "/casino/sn-rng-akbar-romeo-walter",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGAkbarRomeoWalter.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng akbar romeo walter",
        name: "RNG Akbar Romeo Walter",
      },
      {
        href: "/casino/sn-rng-andar-bahar-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGAndarBahar2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng andar bahar 2020",
        name: "RNG Andar Bahar 2020",
      },
      {
        href: "/casino/sn-rng-baccarat",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng baccarat",
        name: "RNG Baccarat",
      },
      {
        href: "/casino/sn-rng-baccarat-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGBaccarat2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng baccarat 2020",
        name: "RNG Baccarat 2020",
      },
      {
        href: "/casino/sn-rng-casino-queen",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGCasinoQueen.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng casino queen",
        name: "RNG Casino Queen",
      },
      {
        href: "/casino/sn-rng-casino-queen-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGCasinoQueen2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng casino queen 2020",
        name: "RNG Casino Queen 2020",
      },
      {
        href: "/casino/sn-rng-cricket-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGCricket2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng cricket 2020",
        name: "RNG Cricket 2020",
      },
      {
        href: "/casino/sn-rng-dragon-tiger",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGDragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng dragon tiger",
        name: "RNG Dragon Tiger",
      },
      {
        href: "/casino/sn-rng-dragon-tiger-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGDragonTiger2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng dragon tiger 2020",
        name: "RNG Dragon Tiger 2020",
      },
      {
        href: "/casino/sn-rng-joker",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGJoker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng joker",
        name: "RNG Joker",
      },
      {
        href: "/casino/sn-rng-kabutar-fuddi",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGKabutarFuddi.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng kabutar fuddi",
        name: "RNG Kabutar Fuddi",
      },
      {
        href: "/casino/sn-rng-king-race",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGKingRace.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng king race",
        name: "RNG King Race",
      },
      {
        href: "/casino/sn-rng-lucky-7",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGLucky7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng lucky 7",
        name: "RNG Lucky 7",
      },
      {
        href: "/casino/sn-rng-poker",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGPoker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng poker",
        name: "RNG Poker",
      },
      {
        href: "/casino/sn-rng-poker-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGPoker2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng poker 2020",
        name: "RNG Poker 2020",
      },
      {
        href: "/casino/sn-rng-teen-patti",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGTeenPatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng teen patti",
        name: "RNG Teen patti",
      },
      {
        href: "/casino/sn-rng-teen-patti-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGTeenPatti2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng teen patti 2020",
        name: "RNG Teen patti 2020",
      },
      {
        href: "/casino/sn-rng-worli-matka",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/RNGWorliMatka.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rng worli matka",
        name: "RNG Worli Matka",
      },
      // {
      //   href: "/casino/sn-roulette",
      //   code: "",
      //   casino: "supernowa",
      //   provider: "WorldCasino",
      //   homeUrl: "/supernowa",
      //   imgUrl: "./images/providers/supernowa/Roulette.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "roulette",
      //   name: "Roulette",
      // },
      {
        href: "/casino/sn-teen-patti",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/TeenPatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "teen patti",
        name: "Teen Patti",
      },
      {
        href: "/casino/sn-teen-patti-2020",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/TeenPatti2020.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "teen patti 2020",
        name: "Teen Patti 2020",
      },
      {
        href: "/casino/sn-teen-patti-face-off",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/TeenPattiFaceOff.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "teen patti face-off",
        name: "Teen Patti face-off",
      },
      {
        href: "/casino/sn-worli-matka",
        code: "",
        casino: "supernowa",
        provider: "WorldCasino",
        homeUrl: "/supernowa",
        imgUrl: "./images/providers/supernowa/WorliMatka.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "worli matka",
        name: "Worli Matka",
      },
    ],
  },

  xpg: {
    XPG: [
      // {
      //   href: "/casino/xpg-andar-bahar",
      //   code: "",
      //   casino: "xpg",
      //   provider: "XPG",
      //   homeUrl: "/xpg",
      //   imgUrl: "./images/providers/xpg/AndarBahar.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "andar bahar",
      //   name: "Andar Bahar",
      // },
      {
        href: "/casino/xpg-baccarat",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/Baccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat",
        name: "Baccarat",
      },
      {
        href: "/casino/xpg-black-jack",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/BlackJack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "black jack",
        name: "Black Jack",
      },
      {
        href: "/casino/xpg-32-cards",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/Cards32.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "32 cards",
        name: "32 Cards",
      },
      {
        href: "/casino/xpg-dragon-tiger-hd",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/DragonTigerHD.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon tiger hd",
        name: "Dragon Tiger HD",
      },
      {
        href: "/casino/xpg-dragon-tiger",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/FastGoldDragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fast gold dragon tiger",
        name: "Fast Gold Dragon Tiger",
      },
      {
        href: "/casino/xpg-sicbo",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/Sicbo.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "sicbo",
        name: "Sicbo",
      },
      {
        href: "/casino/xpg-teen-patti",
        code: "",
        casino: "xpg",
        provider: "XPG",
        homeUrl: "/xpg",
        imgUrl: "./images/providers/xpg/Teenpatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "teen patti",
        name: "Teen Patti",
      },
    ],
  },

  vivo: {
    vivo: [
      {
        href: "/casino/vivo-dragon-tiger",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/DragonTiger.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Dragon Tiger",
        name: "Dragon Tiger",
      },
      {
        href: "/casino/vivo-european-auto-roulette",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/EuropeanAutoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "European Auto Roulette",
        name: "European Auto Roulette",
      },
      {
        href: "/casino/vivo-french-roulette",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/FrenchRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "French Roulette",
        name: "French Roulette",
      },
      {
        href: "/casino/vivo-galaxy-baccarat-1",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/GalaxyBaccarat1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Galaxy Baccarat1",
        name: "Galaxy Baccarat1",
      },
      {
        href: "/casino/vivo-galaxy-baccarat-2",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/GalaxyBaccarat2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Galaxy Baccarat 2",
        name: "Galaxy Baccarat 2",
      },
      {
        href: "/casino/vivo-galaxy-baccarat-3",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/GalaxyBaccarat3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Galaxy Baccarat 3",
        name: "Galaxy Baccarat 3",
      },
      {
        href: "/casino/vivo-galaxy-roulette",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/GalaxyRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Galaxy Roulette",
        name: "Galaxy Roulette",
      },
      {
        href: "/casino/vivo-macau-baccarat",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/MacauBaccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Macau Baccarat",
        name: "Macau Baccarat",
      },
      {
        href: "/casino/vivo-oracle-360-roulette",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/Oracle360Roulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Oracle 360 Roulette",
        name: "Oracle 360 Roulette",
      },
      {
        href: "/casino/vivo-oracle-blaze-roulette",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/OracleBlazeRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Oracle Blaze Roulette",
        name: "Oracle Blaze Roulette",
      },
      {
        href: "/casino/vivo-portomaso-roulette",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/PortomasoRoulette.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Portomaso Roulette",
        name: "Portomaso Roulette",
      },
      {
        href: "/casino/vivo-teen-patti",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/TeenPatti.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Teen Patti",
        name: "Teen Patti",
      },
      {
        href: "/casino/vivo-va-bacarat-1",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/VABacarat1.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "VA Bacarat 1",
        name: "VA Bacarat 1",
      },
      {
        href: "/casino/vivo-va-bacarat-2",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/VABacarat2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "VA Bacarat 2",
        name: "VA Bacarat 2",
      },
      {
        href: "/casino/vivo-va-bacarat-3",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/VABacarat3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "VA Bacarat 3",
        name: "VA Bacarat 3",
      },
      {
        href: "/casino/vivo-va-bacarat-4",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/VABacarat4.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "VA Bacarat 4",
        name: "VA Bacarat 4",
      },
      {
        href: "/casino/vivo-va-bacarat-5",
        code: "",
        casino: "vivo",
        Provider: "Vivo",
        homeUrl: "/vivo",
        imgUrl: "./images/vivo/VABacarat5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "VA Bacarat 5",
        name: "VA Bacarat 5",
      },
    ],
  },

  onetouch: {
    Onetouch: [
      // {
      //   href: "/casino/ot-andar-bahar",
      //   code: "",
      //   casino: "onetouch",
      //   provider: "WorldCasino",
      //   homeUrl: "/onetouch",
      //   imgUrl: "./images/providers/onetouch/AndarBahar.jpg",
      //   plaBtn: "./images/play-btn.png",
      //   alt: "andar bahar",
      //   name: "Andar Bahar",
      // },
      {
        href: "/casino/ot-baccarat",
        code: "",
        casino: "onetouch",
        provider: "WorldCasino",
        homeUrl: "/onetouch",
        imgUrl: "./images/providers/onetouch/Baccarat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat",
        name: "Baccarat",
      },
      {
        href: "/casino/ot-baccarat-no-commission",
        code: "",
        casino: "onetouch",
        provider: "WorldCasino",
        homeUrl: "/onetouch",
        imgUrl: "./images/providers/onetouch/BaccaratNoCommission.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat no commission",
        name: "Baccarat No Commission",
      },
      {
        href: "/casino/ot-baccarat-supreme",
        code: "",
        casino: "onetouch",
        provider: "WorldCasino",
        homeUrl: "/onetouch",
        imgUrl: "./images/providers/onetouch/BaccaratSupreme.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat supreme",
        name: "Baccarat Supreme",
      },
      {
        href: "/casino/ot-baccarat-supreme-no-commission",
        code: "",
        casino: "onetouch",
        provider: "WorldCasino",
        homeUrl: "/onetouch",
        imgUrl: "./images/providers/onetouch/BaccaratSupremeNoCommission.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "baccarat supreme no commission",
        name: "Baccarat Supreme No Commission",
      },
      {
        href: "/casino/ot-beats-ex",
        code: "",
        casino: "onetouch",
        provider: "WorldCasino",
        homeUrl: "/onetouch",
        imgUrl: "./images/providers/onetouch/BeatsEX.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "beats ex",
        name: "Beats EX",
      },
      {
        href: "/casino/ot-blackjack-classic",
        code: "",
        casino: "onetouch",
        provider: "WorldCasino",
        homeUrl: "/onetouch",
        imgUrl: "./images/providers/onetouch/BlackjackClassic.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "blackjack classic",
        name: "Blackjack Classic",
      },
    ],
  },

  pragmatic: {
    Pragmatic: [
      {
        href: "/casino/pp-aladdin-and-the-sorcerer",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/AladdinandtheSorcerer.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "aladdin and the sorcerer",
        name: "Aladdin and the Sorcerer",
      },
      {
        href: "/casino/pp-amazing-money-machine",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/AmazingMoneyMachine.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "amazing money machine",
        name: "Amazing Money Machine",
      },
      {
        href: "/casino/pp-ancient-egypt",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/AncientEgypt.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "ancient egypt",
        name: "Ancient Egypt",
      },
      {
        href: "/casino/pp-asgard",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Asgard.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "asgard",
        name: "Asgard",
      },
      {
        href: "/casino/pp-aztec-gems",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/AztecGems.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "aztec gems",
        name: "Aztec Gems",
      },
      {
        href: "/casino/pp-aztec-king",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/AztecKing.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "aztec king",
        name: "Aztec King",
      },
      {
        href: "/casino/pp-aztec-king-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/AztecKingMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "aztec king megaways",
        name: "Aztec King Megaways",
      },
      {
        href: "/casino/pp-barn-festival",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BarnFestival.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "barn festival",
        name: "Barn Festival",
      },
      {
        href: "/casino/pp-beowulf",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Beowulf.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "beowulf",
        name: "Beowulf",
      },
      {
        href: "/casino/pp-big-bass-bonanza-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BigBassBonanzaMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "big bass bonanza megaways",
        name: "Big Bass Bonanza Megaways",
      },
      {
        href: "/casino/pp-big-bass-splash",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Bigbasssplash.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "big bass splash",
        name: "Big Bass Splash",
      },
      {
        href: "/casino/pp-bigger-bass-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BiggerBassBonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bigger bass bonanza",
        name: "Bigger Bass Bonanza",
      },
      {
        href: "/casino/pp-big-juan",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BigJuan.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "big juan",
        name: "Big Juan",
      },
      {
        href: "/casino/pp-bomb-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Bombbonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bomb bonanza",
        name: "Bomb bonanza",
      },
      {
        href: "/casino/pp-book-of-aztec-king",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BookofAztecKing.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "book of aztec king",
        name: "Book of Aztec King",
      },
      {
        href: "/casino/pp-book-of-the-fallen",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BookofFallen.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "book of fallen",
        name: "Book of Fallen",
      },
      {
        href: "/casino/pp-bounty-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BountyGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bounty gold",
        name: "Bounty Gold",
      },
      {
        href: "/casino/pp-bubble-pop",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BubblePop.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bubble pop",
        name: "Bubble Pop",
      },
      {
        href: "/casino/pp-buffalo-king",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BuffaloKing.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "buffalo king",
        name: "Buffalo King",
      },
      {
        href: "/casino/pp-buffalo-king-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BuffaloKingMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "buffalo king megaways",
        name: "Buffalo King Megaways",
      },
      {
        href: "/casino/pp-bull-fiesta",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BullFiesta.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "bull fiesta",
        name: "Bull Fiesta",
      },
      {
        href: "/casino/pp-busy-bees",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/BusyBees.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "busy bees",
        name: "Busy Bees",
      },
      {
        href: "/casino/pp-caishens-cash",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Caishen'sCash.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "caishen's cash",
        name: "Caishen's Cash",
      },
      {
        href: "/casino/pp-candy-village",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CandyVillage.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "candy village",
        name: "Candy Village",
      },
      {
        href: "/casino/pp-cash-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CashBonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cash bonanza",
        name: "Cash Bonanza",
      },
      {
        href: "/casino/pp-cash-elevator",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CashElevator.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cash elevator",
        name: "Cash Elevator",
      },
      {
        href: "/casino/pp-cash-patrol",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CashPatrol.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cash patrol",
        name: "Cash Patrol",
      },
      {
        href: "/casino/pp-cheeky-emperor",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Cheekyemperor.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cheeky emperor",
        name: "Cheeky emperor",
      },
      {
        href: "/casino/pp-chicken-chase",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ChickenChase.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "chicken chase",
        name: "Chicken Chase",
      },
      {
        href: "/casino/pp-chicken-drop",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ChickenDrop.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "chicken drop",
        name: "Chicken Drop",
      },
      {
        href: "/casino/pp-chilli-heat",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ChilliHeat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "chilli heat",
        name: "Chilli Heat",
      },
      {
        href: "/casino/pp-chilli-heat-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ChilliHeatMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "chilli heat megaways",
        name: "Chilli Heat Megaways",
      },
      {
        href: "/casino/pp-christmas-big-bass-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ChristmasBigBassBonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "christmas big bass bonanza",
        name: "Christmas Big Bass Bonanza",
      },
      {
        href: "/casino/pp-clover-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CloverGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "clover gold",
        name: "Clover Gold",
      },
      {
        href: "/casino/pp-coffee-wild",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Coffeewild.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "coffee wild",
        name: "Coffee wild",
      },
      {
        href: "/casino/pp-colossal-cash-zone",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ColossalCashZone.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "colossal cash zone",
        name: "Colossal Cash Zone",
      },
      {
        href: "/casino/pp-congo-cash",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CongoCash.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "congo cash",
        name: "Congo Cash",
      },
      {
        href: "/casino/pp-cosmic-cash",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Cosmicsplash.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "cosmic cash",
        name: "Cosmic Cash",
      },
      {
        href: "/casino/pp-crystal-caverns-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/CrystalCavernsMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "crystal caverns megaways",
        name: "Crystal Caverns Megaways",
      },
      {
        href: "/casino/pp-devils-13",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Devils13.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "devils 13",
        name: "Devils 13",
      },
      {
        href: "/casino/pp-diamonds-are-forever-3-lines",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DiamondsareForever3Lines.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "diamonds are forever 3 lines",
        name: "Diamonds are Forever 3 Lines",
      },
      {
        href: "/casino/pp-diamond-strike",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DiamondStrike.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "diamond strike",
        name: "Diamond Strike",
      },
      {
        href: "/casino/pp-disco-lady",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DiscoLady.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "disco lady",
        name: "Disco Lady",
      },
      {
        href: "/casino/pp-down-the-rails",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Downtherails.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "down the rails",
        name: "Down the rails",
      },
      {
        href: "/casino/pp-dragon-hot-hold-and-spin",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DragonHotHoldandSpin.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon hot hold and spin",
        name: "Dragon Hot Hold and Spin",
      },
      {
        href: "/casino/pp-dragon-kingdom",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DragonKingdom.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon kingdom",
        name: "Dragon Kingdom",
      },
      {
        href: "/casino/pp-dragon-kingdom-eyes-of-fire",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DragonKingdomEyesofFire.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dragon kingdom eyes of fire",
        name: "Dragon Kingdom Eyes of Fire",
      },
      {
        href: "/casino/pp-888-dragons",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Dragons888.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "888 dragons",
        name: "888 Dragons",
      },
      {
        href: "/casino/pp-drill-that-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DrillthatGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "drill that gold",
        name: "Drill that Gold",
      },
      {
        href: "/casino/pp-dwarven-gold-deluxe",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/DwarvenGoldDeluxe.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "dwarven gold deluxe",
        name: "Dwarven Gold Deluxe",
      },
      {
        href: "/casino/pp-egyptian-fortunes",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/EgyptianFortunes.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "egyptian fortunes",
        name: "Egyptian Fortunes",
      },
      {
        href: "/casino/pp-elemental-gems-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ElementalGemsMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "elemental gems megaways",
        name: "Elemental Gems Megaways",
      },
      {
        href: "/casino/pp-emerald-King-rainbow-road",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/EmeraldKingRainbowRoad.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "emerald king rainbow road",
        name: "Emerald King Rainbow Road",
      },
      {
        href: "/casino/pp-emperor-caishen",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/EmperorCaishen.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "emperor caishen",
        name: "Emperor Caishen",
      },
      {
        href: "/casino/pp-empty-the-bank",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/EmptytheBank.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "empty the bank",
        name: "Empty the Bank",
      },
      {
        href: "/casino/pp-extra-juicy",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ExtraJuicy.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "extra juicy",
        name: "Extra Juicy",
      },
      {
        href: "/casino/pp-extra-juicy-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/ExtraJuicyMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "extra juicy megaways",
        name: "Extra Juicy Megaways",
      },
      {
        href: "/casino/pp-eye-of-cleopatra",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/EyeofCleopatra.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "eye of cleopatra",
        name: "Eye of Cleopatra",
      },
      {
        href: "/casino/pp-eye-of-the-storm",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/EyeoftheStorm.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "eye of the storm",
        name: "Eye of the Storm",
      },
      {
        href: "/casino/pp-fairytale-fortune",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/fairytalefortune.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fairytale fortune",
        name: "Fairytale Fortune",
      },
      {
        href: "/casino/pp-fire-88",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Fire88.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fire 88",
        name: "Fire 88",
      },
      {
        href: "/casino/pp-fire-hot-20",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Firehot20.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fire-hot 20",
        name: "Fire-hot 20",
      },
      {
        href: "/casino/pp-fire-hot-40",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Firehot40.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fire-hot 40",
        name: "Fire-hot 40",
      },
      {
        href: "/casino/pp-fire-hot-100",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/FireHot100.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fire-hot 100",
        name: "Fire-hot 100",
      },
      {
        href: "/casino/pp-fire-strike",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/FireStrike.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fire strike",
        name: "Fire Strike",
      },
      {
        href: "/casino/pp-fire-strike-2",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Firestrike2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fire strike 2",
        name: "Fire Strike 2",
      },
      {
        href: "/casino/pp-fishin-reels",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/FishinReels.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fishin reels",
        name: "Fishin Reels",
      },
      {
        href: "/casino/pp-floating-dragon",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/FloatingDragon.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "floating dragon",
        name: "Floating Dragon",
      },
      {
        href: "/casino/pp-fortune-of-giza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/FortuneofGiza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fortune of giza",
        name: "Fortune of Giza",
      },
      {
        href: "/casino/pp-fruit-party-2",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/FruitParty2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fruit party 2",
        name: "Fruit Party 2",
      },
      {
        href: "/casino/pp-gates-of-olympus",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GatesofOlympus.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gates of olympus",
        name: "Gates of Olympus",
      },
      {
        href: "/casino/pp-gates-of-valhalla",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GatesofValhalla.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gates of valhalla",
        name: "Gates of Valhalla",
      },
      {
        href: "/casino/pp-3-genie-wishes",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GenieWishes3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "3 genie wishes",
        name: "3 Genie Wishes",
      },
      {
        href: "/casino/pp-goblin-heist-powernudge",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GoblinHeistPowernudge.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "goblin heist powernudge",
        name: "Goblin Heist Powernudge",
      },
      {
        href: "/casino/pp-888-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Gold888.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "888 gold",
        name: "888 Gold",
      },
      {
        href: "/casino/pp-golden-beauty",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GoldenBeauty.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "golden beauty",
        name: "Golden Beauty",
      },
      {
        href: "/casino/pp-golden-pig",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GoldenPig.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "golden pig",
        name: "Golden Pig",
      },
      {
        href: "/casino/pp-gold-party",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GoldParty.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gold party",
        name: "Gold Party",
      },
      {
        href: "/casino/pp-gold-rush",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GoldRush.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gold rush",
        name: "Gold Rush",
      },
      {
        href: "/casino/pp-gold-train",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GoldTrain.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gold train",
        name: "Gold Train",
      },
      {
        href: "/casino/pp-gorilla-mayhem",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GorillaMayhem.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "gorilla mayhem",
        name: "Gorilla Mayhem",
      },
      {
        href: "/casino/pp-great-reef",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GreatReef.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "great reef",
        name: "Great Reef",
      },
      {
        href: "/casino/pp-greedy-wolf",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Greedywolf.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "greedy wolf",
        name: "Greedy wolf",
      },
      {
        href: "/casino/pp-greek-gods",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/GreekGods.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "greek gods",
        name: "Greek Gods",
      },
      {
        href: "/casino/pp-heart-of-rio",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HeartofRio.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "heart of rio",
        name: "Heart of Rio",
      },
      {
        href: "/casino/pp-hercules-and-pegasus",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HerculesandPegasus.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hercules and pegasus",
        name: "Hercules and Pegasus",
      },
      {
        href: "/casino/pp-hercules-son-of-zeus",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HerculesSonOfZeus.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hercules son of zeus",
        name: "Hercules Son of Zeus",
      },
      {
        href: "/casino/pp-hockey-attack",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HockeyAttack.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hockey attack",
        name: "Hockey Attack",
      },
      {
        href: "/casino/pp-hokkaido-wolf",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HokkaidoWolf.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hokkaido wolf",
        name: "Hokkaido Wolf",
      },
      {
        href: "/casino/pp-honey-honey-honey",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HoneyHoneyHoney.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "honey honey honey",
        name: "Honey Honey Honey",
      },
      {
        href: "/casino/pp-hot-chilli",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HotChilli.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot chilli",
        name: "Hot Chilli",
      },
      {
        href: "/casino/pp-hot-fiesta",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HotFiesta.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot fiesta",
        name: "Hot Fiesta",
      },
      {
        href: "/casino/pp-hot-safari",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HotSafari.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot safari",
        name: "Hot Safari",
      },
      {
        href: "/casino/pp-hot-to-burn-extreme",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Hottoburnextreme.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot to burn extreme",
        name: "Hot to Burn Extreme",
      },
      {
        href: "/casino/pp-hot-to-burn-hold-and-spin",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/HottoBurnHoldandSpin.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot to burn hold and spin",
        name: "Hot to Burn Hold and Spin",
      },
      {
        href: "/casino/pp-irish-charms",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/IrishCharms.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "irish charms",
        name: "Irish Charms",
      },
      {
        href: "/casino/pp-jade-butterfly",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/JadeButterfly.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "jade butterfly",
        name: "Jade Butterfly",
      },
      {
        href: "/casino/pp-john-hunter-and-the-quest-for-bermuda-riches",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/JohnHunterandBermudaRiches.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "john hunter and bermuda riches",
        name: "John Hunter and Bermuda Riches",
      },
      {
        href: "/casino/pp-john-hunter-and-the-tomb-of-the-scarab-queen",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/JohnHunterandScarabQueen.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "john hunter and scarab queen",
        name: "John Hunter and Scarab Queen",
      },
      {
        href: "/casino/pp-joker-king",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/JokerKing.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "joker king",
        name: "Joker king",
      },
      {
        href: "/casino/pp-jokers-jewels",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Joker'sJewels.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "joker's jewels",
        name: "Joker's Jewels",
      },
      {
        href: "/casino/pp-journey-to-the-west",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/JourneytotheWest.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "journey to the west",
        name: "Journey to the West",
      },
      {
        href: "/casino/pp-juicy-fruits",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/JuicyFruits.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "juicy fruits",
        name: "Juicy Fruits",
      },
      {
        href: "/casino/pp-3-kingdoms-battle-of-red-cliffs",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/KingdomsBattleofRedCliffs3.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "3 kingdoms - battle of red cliffs",
        name: "3 Kingdoms - Battle of Red Cliffs",
      },
      {
        href: "/casino/pp-koi-pond",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/KoiPond.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "koi pond",
        name: "Koi Pond",
      },
      {
        href: "/casino/pp-lady-godiva",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LadyGodiva.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lady godiva",
        name: "Lady Godiva",
      },
      {
        href: "/casino/pp-5-lions-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LionsGold5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lions gold 5",
        name: "Lions Gold 5",
      },
      {
        href: "/casino/pp-5-lions-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LionsMegaways5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "5 lions megaways",
        name: "5 Lions Megaways",
      },
      {
        href: "/casino/pp-little-gem-hold-and-spin",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LittleGemHold&Spin.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "little gem hold and spin",
        name: "Little Gem Hold and Spin",
      },
      {
        href: "/casino/pp-lucky-dragons",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LuckyDragons.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky dragons",
        name: "Lucky Dragons",
      },
      {
        href: "/casino/pp-lucky-grace-and-charm",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LuckyGraceAndCharm.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky grace and charm",
        name: "Lucky Grace and Charm",
      },
      {
        href: "/casino/pp-lucky-lightning",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LuckyLightning.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky lightning",
        name: "Lucky Lightning",
      },
      {
        href: "/casino/pp-lucky-new-year",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LuckyNewYear.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky new year",
        name: "Lucky New Year",
      },
      {
        href: "/casino/pp-lucky-new-year-tiger-treasures",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/LuckyNewYear-TigerTreasures.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky new year tiger treasures",
        name: "Lucky New Year Tiger Treasures",
      },
      {
        href: "/casino/pp-madame-destiny",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MadameDestiny.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "madame destiny",
        name: "Madame Destiny",
      },
      {
        href: "/casino/pp-madame-destiny-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MadameDestinyMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "madame destiny megaways",
        name: "Madame Destiny Megaways",
      },
      {
        href: "/casino/pp-magicians-secrets",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Magician'sSecrets.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "magician's secrets",
        name: "Magician's Secrets",
      },
      {
        href: "/casino/pp-magic-journey",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MagicJourney.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "magic journey",
        name: "Magic Journey",
      },
      {
        href: "/casino/pp-magic-money-maze",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Magicmoneymaze.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "magic money maze",
        name: "Magic Money Maze",
      },
      {
        href: "/casino/pp-mahjong-panda",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MahjongPanda.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "mahjong panda",
        name: "Mahjong Panda",
      },
      {
        href: "/casino/pp-master-joker",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MasterJoker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "master joker",
        name: "Master Joker",
      },
      {
        href: "/casino/pp-might-of-ra",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MightofRa.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "might of ra",
        name: "Might of Ra",
      },
      {
        href: "/casino/pp-mighty-kong",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MightyKong.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "mighty kong",
        name: "Mighty Kong",
      },
      {
        href: "/casino/pp-money-mouse",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MoneyMouse.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "money mouse",
        name: "Money Mouse",
      },
      {
        href: "/casino/pp-money-roll",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MoneyRoll.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "money roll",
        name: "Money Roll",
      },
      {
        href: "/casino/pp-monkey-madness",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Monkeymadness.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "monkey madness",
        name: "Monkey Madness",
      },
      {
        href: "/casino/pp-7-monkeys",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Monkeys7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "7 monkeys",
        name: "7 Monkeys",
      },
      {
        href: "/casino/pp-monkey-warrior",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MonkeyWarrior.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "monkey warrior",
        name: "Monkey Warrior",
      },
      {
        href: "/casino/pp-mysterious",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Mysterious.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "mysterious",
        name: "Mysterious",
      },
      {
        href: "/casino/pp-mystic-chief",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/MysticChief.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "mystic chief",
        name: "Mystic Chief",
      },
      {
        href: "/casino/pp-north-guardians",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Northguardians.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "north guardians",
        name: "North Guardians",
      },
      {
        href: "/casino/pp-panda-fortune-2",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PandaFortune2.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "panda fortune 2",
        name: "Panda Fortune 2",
      },
      {
        href: "/casino/pp-pandas-fortune",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Panda'sFortune.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "panda's fortune",
        name: "Panda's Fortune",
      },
      {
        href: "/casino/pp-phoenix-forge",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PhoenixForge.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "phoenix forge",
        name: "Phoenix Forge",
      },
      {
        href: "/casino/pp-7-piggies",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Piggies7.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "7 piggies",
        name: "7 Piggies",
      },
      {
        href: "/casino/pp-piggy-bank-bills",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PiggyBankBills.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "piggy bank bills",
        name: "Piggy Bank Bills",
      },
      {
        href: "/casino/pp-pirate-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PirateGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "pirate gold",
        name: "Pirate Gold",
      },
      {
        href: "/casino/pp-pixie-wings",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PixieWings.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "pixie wings",
        name: "Pixie Wings",
      },
      {
        href: "/casino/pp-power-of-thor-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PowerofThorMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "power of thor megaways",
        name: "Power of Thor Megaways",
      },
      {
        href: "/casino/pp-pyramid-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/PyramidBonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "pyramid bonanza",
        name: "Pyramid Bonanza",
      },
      {
        href: "/casino/pp-queenie",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Queenie.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "queenie",
        name: "Queenie",
      },
      {
        href: "/casino/pp-queen-of-gods",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/QueenofGods.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "queen of gods",
        name: "Queen of Gods",
      },
      {
        href: "/casino/pp-queen-of-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/QueenOfGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "queen of gold",
        name: "Queen of Gold",
      },
      {
        href: "/casino/pp-raging-bull",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/RagingBull.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "raging bull",
        name: "Raging Bull",
      },
      {
        href: "/casino/pp-rainbow-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/RainbowGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rainbow gold",
        name: "Rainbow Gold",
      },
      {
        href: "/casino/pp-rise-of-giza-powernudge",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/RiseofGizaPowerNudge.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rise of giza powernudge",
        name: "Rise of Giza PowerNudge",
      },
      {
        href: "/casino/pp-rise-of-samurai",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Riseofsamurai.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rise of samurai",
        name: "Rise of Samurai",
      },
      {
        href: "/casino/pp-rise-of-samurai-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/RiseofSamuraiMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rise of samurai megaways",
        name: "Rise of Samurai Megaways",
      },
      {
        href: "/casino/pp-rock-vegas",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/RockVegas.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "rock vegas",
        name: "Rock Vegas",
      },
      {
        href: "/casino/pp-safari-king",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SafariKing.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "safari king",
        name: "Safari King",
      },
      {
        href: "/casino/pp-santa",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/santa.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "santa",
        name: "Santa",
      },
      {
        href: "/casino/pp-santas-wonderland",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SantasWonderland.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "Santa's wonderland",
        name: "Santa's Wonderland",
      },
      {
        href: "/casino/pp-shining-hot-5",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Shininghot5.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "shining hot 5",
        name: "Shining Hot 5",
      },
      {
        href: "/casino/pp-shining-hot-20",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Shininghot20.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "shining hot 20",
        name: "Shining Hot 20",
      },
      {
        href: "/casino/pp-shining-hot-40",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Shininghot40.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "shining hot 40",
        name: "Shining Hot 40",
      },
      {
        href: "/casino/pp-shining-hot-100",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Shininghot100.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "shining hot 100",
        name: "Shining Hot 100",
      },
      {
        href: "/casino/pp-smugglers-cove",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SmugglersCove.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "smugglers cove",
        name: "Smugglers Cove",
      },
      {
        href: "/casino/pp-snakes-and-ladders-megadice",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SnakesandLaddersMegadice.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "snakes and ladders megadice",
        name: "Snakes and Ladders Megadice",
      },
      {
        href: "/casino/pp-spaceman",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Spaceman.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "spaceman",
        name: "Spaceman",
      },
      {
        href: "/casino/pp-spirit-of-adventure",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Spiritofadventure.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "spirit of adventure",
        name: "Spirit of Adventure",
      },
      {
        href: "/casino/pp-starlight-princess",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/StarlightPrincess.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "starlight princess",
        name: "Starlight Princess",
      },
      {
        href: "/casino/pp-star-pirates-code",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/StarPiratesCode.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "star pirates code",
        name: "Star Pirates Code",
      },
      {
        href: "/casino/pp-sugar-rush",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SugarRush.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "sugar rush",
        name: "Sugar Rush",
      },
      {
        href: "/casino/pp-super-7s",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Super7s.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "super 7s",
        name: "Super 7s",
      },
      {
        href: "/casino/pp-super-joker",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SuperJoker.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "super joker",
        name: "Super Joker",
      },
      {
        href: "/casino/pp-super-x",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SuperX.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "super x",
        name: "Super X",
      },
      {
        href: "/casino/pp-sweet-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SweetBonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "sweet bonanza",
        name: "Sweet Bonanza",
      },
      {
        href: "/casino/pp-sweet-bonanza-xmas",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/SweetBonanzaXmas.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "sweet bonanza xmas",
        name: "Sweet Bonanza Xmas",
      },
      {
        href: "/casino/pp-temujin-treasures",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/TemujinTreasures.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "temujin treasures",
        name: "Temujin Treasures",
      },
      {
        href: "/casino/pp-the-dog-house",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/TheDogHouse.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the dog house",
        name: "The Dog House",
      },
      {
        href: "/casino/pp-the-great-chicken-escape",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/TheGreatChickenEscape.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the great chicken escape",
        name: "The Great Chicken Escape",
      },
      {
        href: "/casino/pp-the-great-stick-up",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Thegreatstickup.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the great stick-up",
        name: "The Great Stick-Up",
      },
      {
        href: "/casino/pp-the-hand-of-midas",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/TheHandofMidas.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the hand of midas",
        name: "The Hand of Midas",
      },
      {
        href: "/casino/pp-the-magic-cauldron",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/TheHandofMidas.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the magic cauldron",
        name: "The Magic Cauldron",
      },
      {
        href: "/casino/pp-the-tweety-house",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/TheTweetyHouse.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the tweety house",
        name: "The Tweety House",
      },
      {
        href: "/casino/pp-vegas-nights",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/VegasNights.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "vegas nights",
        name: "Vegas Nights",
      },
      {
        href: "/casino/pp-wild-beach-party",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildBeachParty.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild beach party",
        name: "Wild Beach Party",
      },
      {
        href: "/casino/pp-wild-booster",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildBooster.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild booster",
        name: "Wild Booster",
      },
      {
        href: "/casino/pp-wild-depths",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildDepths.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild depths",
        name: "Wild Depths",
      },
      {
        href: "/casino/pp-wild-gladiator",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildGladiator.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild gladiator",
        name: "Wild Gladiator",
      },
      {
        href: "/casino/pp-wildman-super-bonanza",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/Wildmansuperbonanza.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wildman super bonanza",
        name: "WildMan Super Bonanza",
      },
      {
        href: "/casino/pp-wild-pixies",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildPixies.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild pixies",
        name: "Wild Pixies",
      },
      {
        href: "/casino/pp-wild-spells",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildSpells.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild spells",
        name: "Wild Spells",
      },
      {
        href: "/casino/pp-wild-west-gold-megaways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WildWestGoldMegaways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wild west gold megaways",
        name: "Wild West Gold Megaways",
      },
      {
        href: "/casino/pp-wolf-gold",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/WolfGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "wolf gold",
        name: "Wolf Gold",
      },
      {
        href: "/casino/pp-yum-yum-powerways",
        code: "",
        casino: "pragmatic",
        provider: "WorldCasino",
        homeUrl: "/pragmatic",
        imgUrl: "./images/providers/pragmetic/YumYumPowerways.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "yum yum powerways",
        name: "Yum Yum Powerways",
      },
    ],
  },

  onlyplay: {
    Onlyplay: [
      {
        href: "/casino/gt-book-of-bamboo",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/BookofBamboo.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "book of bamboo",
        name: "Book of Bamboo",
      },
      {
        href: "/casino/gt-book-of-eye",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/BookofEye.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "book of eye",
        name: "Book of Eye",
      },
      {
        href: "/casino/gt-chervona-kalyna",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/ChervonaKalyna.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "chervona kalyna",
        name: "Chervona Kalyna",
      },
      {
        href: "/casino/gt-crystal-cascade",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/CrystalCascade.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "crystal cascade",
        name: "Crystal Cascade",
      },
      {
        href: "/casino/gt-F777-fighter",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/F777Fighter.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "f777 fighter",
        name: "F777 Fighter",
      },
      {
        href: "/casino/gt-fruity-book",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/FruityBook.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "fruity book",
        name: "Fruity Book",
      },
      {
        href: "/casino/gt-golden-clover",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/GoldenClover.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "golden clover",
        name: "Golden Clover",
      },
      {
        href: "/casino/gt-hot-and-spicy",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/HotandSpicy.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot and spicy",
        name: "Hot and Spicy",
      },
      {
        href: "/casino/gt-hot-and-spicy-jackpot",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/HotandSpicyJackpot.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "hot and spicy jackpot",
        name: "Hot and Spicy Jackpot",
      },
      {
        href: "/casino/gt-iggy-racing",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/IggyRacing.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "iggy racing",
        name: "Iggy Racing",
      },
      {
        href: "/casino/gt-inca-son",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/IncaSon.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "inca son",
        name: "Inca Son",
      },
      {
        href: "/casino/gt-jack-potter",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JackPotter.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "jack potter",
        name: "Jack Potter",
      },
      {
        href: "/casino/gt-jack-potter-deluxe",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JackPotterDeluxe.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "jack potter deluxe",
        name: "Jack Potter Deluxe",
      },
      {
        href: "/casino/gt-jack-potter-x-mas",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JackPotterX-MAS.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "jack potter x-mas",
        name: "Jack Potter X-MAS",
      },
      {
        href: "/casino/gt-joker-coins",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JokerCoins.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "joker coins",
        name: "Joker Coins",
      },
      {
        href: "/casino/gt-joker-coins-x-mas",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JokerCoinsX-MAS.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "joker coins x-mas",
        name: "Joker Coins X-MAS",
      },
      {
        href: "/casino/gt-juicy-crush",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JuicyCrush.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "juicy crush",
        name: "Juicy Crush",
      },
      {
        href: "/casino/gt-jungle-gold",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/JungleGold.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "jungle gold",
        name: "Jungle Gold",
      },
      {
        href: "/casino/gt-limbo-cat",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/LimboCat.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "limbo cat",
        name: "Limbo Cat",
      },
      {
        href: "/casino/gt-lucky-clover",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/LuckyClover.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky clover",
        name: "Lucky Clover",
      },
      {
        href: "/casino/gt-lucky-coin",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/LuckyCoin.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky coin",
        name: "Lucky Coin",
      },
      {
        href: "/casino/gt-lucky-ocean",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/LuckyOcean.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky ocean",
        name: "Lucky Ocean",
      },
      {
        href: "/casino/gt-lucky-punch",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/LuckyPunch.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky punch",
        name: "Lucky Punch",
      },
      {
        href: "/casino/gt-lucky-tanks",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/LuckyTanks.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "lucky tanks",
        name: "Lucky Tanks",
      },
      {
        href: "/casino/gt-myths-of-bastet",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/MythsofBastet.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "myths of bastet",
        name: "Myths of Bastet",
      },
      {
        href: "/casino/gt-royal-kitties",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/RoyalKitties.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "royal kitties",
        name: "Royal Kitties",
      },
      {
        href: "/casino/gt-the-thimbles",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/TheThimbles.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "the thimbles",
        name: "The Thimbles",
      },
      {
        href: "/casino/gt-troll-dice",
        code: "",
        casino: "Only Play",
        provider: "WorldCasino",
        homeUrl: "/onlyplay",
        imgUrl: "./images/providers/onlyplay/TrollDice.jpg",
        plaBtn: "./images/play-btn.png",
        alt: "troll dice",
        name: "Troll Dice",
      },
    ],
  },
};
