import React, { useState, useEffect } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { casinoTabs } from "../../lib/data/casinoTabs.data";
import { casinoProvidersGamesdata } from "../../lib/data/casinoProvidersGamesdata";
import { SlotGames } from "../../lib/data/SlotGames.data";
import { auraGames } from "../../lib/data/auraGames.data";

const SEARCH_TYPES = {
  SLOTS: "slots",
  AURA: "aura",
  HOME: "home",
};

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const totalGames = casinoTabs;

  useEffect(() => {
    return () => {
      setFilteredData(null);
    };
  }, []);

  const handleClose = () => {
    setFilteredData(null);
    setShow(false);
  };

  const filterPageSpecificData = (
    dataToFilter,
    searchValue,
    searchType = "pageSpecific"
  ) => {
    let localFilter = [];
    let slotsCategory = ["netent", "redTiger"];

    Object.values(dataToFilter).map((slot) => {
      if (
        (slot?.key && slotsCategory.includes(slot?.key)) ||
        window.location.pathname.toLowerCase().indexOf(SEARCH_TYPES.AURA) >
          -1 ||
        searchType === "all"
      ) {
        Object.values(slot).map((item) => {
          if (typeof item !== "string") {
            Object.values(item).map((f) => {
              if (
                f?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1
              ) {
                localFilter.push(f);
              }
            });
          }
        });
      }
    });
    return localFilter;
  };

  // TODO need code optimisation in search function
  const handleSearch = (value) => {
    if (value) {
      let filteredValue = [];

      if (
        window.location.pathname.toLowerCase().indexOf(SEARCH_TYPES.SLOTS) > -1
      ) {
        filteredValue = filteredValue.concat(
          filterPageSpecificData(SlotGames, value)
        );
      } else if (window.location.pathname.indexOf(SEARCH_TYPES.AURA) > -1) {
        filteredValue = filteredValue.concat(
          filterPageSpecificData(auraGames, value)
        );
      } else {
        Object.values(totalGames).map((categories) => {
          Object.values(categories).map((item) => {
            if (typeof item !== "string") {
              if (
                item?.name?.toLowerCase().indexOf(value?.toLowerCase()) > -1
              ) {
                filteredValue.push(item);
              }
            }
          });
        });
        Object.values(casinoProvidersGamesdata).map((games) => {
          Object.values(games).map((g) => {
            g.map((f) => {
              if (f?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                filteredValue.push(f);
              }
            });
          });
        });
        filteredValue = filteredValue.concat(
          filterPageSpecificData(SlotGames, value, "all")
        );
        filteredValue = filteredValue.concat(
          filterPageSpecificData(auraGames, value, "all")
        );
      }

      setFilteredData(filteredValue);
    } else {
    }
  };

  return (
    <>
      <div className="search-box">
        <div className="searchGames" onClick={handleShow}>
          <BiSearch />
          <p>Search games...</p>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="search-modal">
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Search Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search your favourite games here..."
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
            />
          </InputGroup>

          <ul className="game-list">
            {filteredData &&
              filteredData.map((item, idx) => (
                <li key={idx}>
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} />
                    <span>{item.name}</span>
                  </a>
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
